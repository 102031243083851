import React from "react"
import { Box, Text, Paragraph, Button } from "grommet"
import Link from "../Link"
import { Heading } from ".."

export const Intro = () => {
  return (
    <Box
      align="center"
      justify="center"
      wrap={false}
      flex="grow"
      width="large"
      direction="column"
      pad="large"
      id="about"
      // data-sal="fade"
      background="background"
      margin="medium"
    >
      <Heading>QUEM SOMOS</Heading>
      <Paragraph margin="medium" fill textAlign="left">
        A <Text weight="bold">Mons Consulting</Text> foi criada em 2020 com o
        intuito de oferecer serviços de consultoria e soluções tecnológicas para
        projetos de desenvolvimento sustentável, ecológico e cultural.
      </Paragraph>
      <Paragraph margin="medium" fill textAlign="left">
        Sendo a nossa base o desenvolvimento sustentável, temos como foco
        regiões interiores e rurais, que apesar de terem um considerável
        potencial em recursos humanos e naturais, têm infraestruturas
        insuficientes e índices de desenvolvimento muito abaixo do seu real
        potencial.
      </Paragraph>
      <Link to="/about">
        <Button color="secondary">Saber mais...</Button>
      </Link>
    </Box>
  )
}
