import React from "react"
import { Formik, Field } from "formik"
import { Box, FormField, TextArea, TextInput, Button } from "grommet"
import * as Yup from "yup"
import axios from "axios"
import styled from "styled-components"
import { Checkbox } from "grommet-icons"

const StyledCheckBox = styled(Checkbox)`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

export const ContactForm = () => {
  //FIXME: Fix env variable
  const formiumUrl =
    "https://api.formium.io/submit/600d9565d0a32c0001f4a2fb/info"
  return (
    <Box fill margin={{ left: "medium" }}>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
          pote: false,
          success: false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Indique-nos o seu nome"),
          email: Yup.string()
            .email("Email inválido")
            .required("É necessário indicar o seu email"),
          message: Yup.string().required("Escreva a sua mensagem por favor"),
          // pote: Yup.string().required("Robots are not welcome yet!"),
        })}
        validateOnBlur
        validateOnChange
        onSubmit={async (
          { name, email, message },
          { setSubmitting, resetForm, setFieldValue }
        ) => {
          try {
            await axios({
              method: "POST",
              url: formiumUrl,
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                name,
                email,
                message,
              }),
            })
            setSubmitting(false)
            setFieldValue("success", true)
            setTimeout(() => resetForm(), 6000)
          } catch (err) {
            setSubmitting(false)
            setFieldValue("success", false)
            alert("Aconteceu algo errado, por favor tente outra vez!") // eslint-disable-line
          }
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* <form  method="post"  netlify-honeypot="bot-field" data-netlify="true" name="contact"> */}
            <Field name="name">
              {({ field, form, meta }) => (
                <FormField
                  label="Nome"
                  error={errors.name && touched.name ? errors.name : ""}
                >
                  <TextInput
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
            </Field>

            <FormField
              label="Email"
              error={errors.email && touched.email ? errors.email : ""}
            >
              <TextInput
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>

            <FormField
              label="Mensagem"
              error={errors.message && touched.message ? errors.message : ""}
            >
              <TextArea
                name="message"
                value={values.message || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            <StyledCheckBox name="pote" />
            {/* <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" /> */}
            {values.success && (
              <FormField>
                <h4>
                  A sua mensagem foi registada com sucesso, iremos entrar em
                  contacto consigo o mais brevemente possível!
                </h4>
              </FormField>
            )}
            <Box
              tag="footer"
              margin={{ top: "medium" }}
              direction="row"
              justify="between"
            >
              <Button
                type="submit"
                primary
                label="Enviar"
                color="accent"
                disabled={!(isValid && dirty) || values.pote}
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
