import React from "react"
import { Parallax } from "react-parallax"
// @ts-ignore
import parallaxImage from "../../assets/backgrounds/backgroundTeamInv.png"
import { Box, ResponsiveContext, Text } from "grommet"
import styled from "styled-components"
// @ts-ignore
import TeamMembersComponent from "./TeamMembersComponent"
import { Heading } from ".."

const StyledContainer = styled(Box)`
  position: relative;
`
export const Team = ({ data }) => {
  return (
    <Box fill="horizontal" id="team" responsive flex elevation="small">
      <Parallax bgImage={parallaxImage} strength={200}>
        <ResponsiveContext.Consumer>
          {size =>
            size === "small" ? (
              <StyledContainer
                align="center"
                justify="center"
                background={{ color: "#007481", dark: true }}
                flex
                pad="medium"
                fill="vertical"
                height="medium"
                wrap
                gap="medium"
                direction="column"
                margin="large"
                responsive
              >
                <Box flex align="center" direction="column">
                  <Heading color="text">A EQUIPA</Heading>
                  <Box flex direction="column" margin="small">
                    <TeamMembersComponent data={data} />
                  </Box>
                  <Text size="small" textAlign="center">
                    Contamos ainda com o apoio de vários consultores competentes
                    em diversas áreas.
                  </Text>
                </Box>
              </StyledContainer>
            ) : (
              <StyledContainer
                align="center"
                justify="center"
                background={{ color: "primary", dark: true }}
                flex
                pad="large"
                // fill="horizontal"
                id="team"
                height="medium"
                wrap
                gap="medium"
                direction="row"
                margin="large"
                responsive
              >
                <Box flex align="center" direction="column">
                  <Text
                    size="xlarge"
                    weight="bold"
                    margin="small"
                    textAlign="center"
                  >
                    A EQUIPA
                  </Text>{" "}
                  <Box flex direction="row" margin="small">
                    <TeamMembersComponent data={data} />
                  </Box>
                  <Text size="medium">
                    Contamos ainda com o apoio de vários consultores competentes
                    em diversas áreas.
                  </Text>
                </Box>
              </StyledContainer>
            )
          }
        </ResponsiveContext.Consumer>
      </Parallax>
    </Box>
  )
}
