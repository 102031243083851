import React from "react"
import { Avatar, Card, CardHeader, Text } from "grommet"

export const TeamMember = ({ avatarPath = "", name, position }) => {
  return (
    <Card
      direction="column"
      align="center"
      style={{
        width: "200px",
        height: "220px",
        borderRadius: "0",
        boxShadow: "none",
      }}
      justify="start"
    >
      <Avatar align="center" justify="center" size="large" src={avatarPath} />
      <CardHeader align="center" pad="small">
        <Text size="large" textAlign="center">
          {name}
        </Text>
      </CardHeader>
      <Text textAlign="center" size="small">
        {position}
      </Text>
    </Card>
  )
}
