import React from "react"
import { Intro } from "../components/landing/Intro"
import { Team } from "../components/landing"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import Contact from "../components/landing/Contact"
import { Grommet, Main, Box } from "grommet"
import { Header } from "../components/header/Header"
import { GlobalStyle, grommetTheme } from "../theme"
import { Footer } from "../components/footer"
import { ParallaxProvider } from "react-scroll-parallax"
import "@fontsource/readex-pro"
// import { SEO } from "../components"

export const query = graphql`
  query MyQuery {
    andre: allFile(filter: { relativePath: { eq: "avatars/Untitled_2.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    dito: allFile(filter: { relativePath: { eq: "avatars/dito.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    parallax_hero: allFile(
      filter: { relativePath: { eq: "backgrounds/bg_21.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const Home = ({ data }) => (
  <Grommet
    // @ts-ignore
    theme={grommetTheme}
  >
    <ParallaxProvider>
      <Box fill="vertical" overflow="auto" align="stretch" flex="grow">
        {/* <SEO title="Home" /> */}

        <GlobalStyle />
        <Header />
        <Main
          flex
          overflow="auto"
          direction="column"
          justify="start"
          fill="horizontal"
          align="center"
        >
          <Hero data={data} />
          <Intro />
          <Team data={data} />
          <Contact />
        </Main>
        <Footer />
      </Box>
    </ParallaxProvider>
  </Grommet>
)

export default Home
