import React from "react"
import { TeamMember } from "./TeamMember"

const TeamMembersComponent = ({ data }) => {
  // console.log("teammembers", data)
  //FIXME: Change avatars
  return (
    <React.Fragment>
      <TeamMember
        name="André Morais"
        position="Engenharia e Tecnologias de Informação"
        avatarPath={
          data.andre.edges[0].node.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
      />
      <TeamMember
        name="Fernando Morais"
        position="Biologia e Empreendedorismo"
        avatarPath={
          data.dito.edges[0].node.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
      />
    </React.Fragment>
  )
}
// <TeamMember
// name="Carlos Santos"
// position="Gestão de Projetos e Eventos"
// avatarPath={
//   data.ponga.edges[0].node.childImageSharp.gatsbyImageData.images
//     .fallback.src
// }
// />

export default TeamMembersComponent
