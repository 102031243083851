import React from "react"
import { Box, Text, ResponsiveContext, Anchor } from "grommet"
import { ContactForm } from "./ContactForm"
import Section from "../Section"
//TODO: Change contact message to notification

const Contact = () => {
  const FieldTitle = ({ children }) => (
    <Text
      weight="bold"
      size="medium"
      color="accent"
      margin={{ right: "xxsmall" }}
    >
      {children}
    </Text>
  )
  return (
    <Section background="background" color="accent" heading="CONTACTOS">
      <ResponsiveContext.Consumer>
        {size =>
          size === "small" ? (
            <Box
              id="contact"
              direction="column"
              justify="start"
              // data-sal="fade"
            >
              <Box fill margin={{ top: "large", bottom: "xlarge" }}>
                <Box margin="xsmall" direction="column">
                  <FieldTitle>Telefone:</FieldTitle>
                  <Anchor color="secondary" href="tel:+351965534750" label="+351 965 534 750"/>
                </Box>
                <Box margin="xsmall" direction="column">
                  <FieldTitle>Email: </FieldTitle>
                  <Anchor color="secondary" href="mailto:info@monsconsulting.com" label="info@monsconsulting.com"/>
                    
                </Box>
                <Box margin="xsmall" direction="column">
                  <FieldTitle>Morada:</FieldTitle>
                  <Text size="medium">
                    Escadinhas da Alba Longa 7/9, RC, 2710-522 Sintra
                  </Text>
                </Box>
              </Box>
              <ContactForm />
            </Box>
          ) : (
            <Box
              direction="row"
              justify="between"
              margin={{ top: "medium" }}
              // data-sal="fade"
              id="contact"
            >
              <Box fill>
                <Box margin="xsmall" direction="row">
                  <FieldTitle>Telefone:</FieldTitle>
                  <a href="tel:+351965534750">+351 965 534 750</a>
                </Box>
                <Box margin="xsmall" direction="row">
                  <FieldTitle>Email: </FieldTitle>
                  <a href="mailto:info@monsconsulting.com">
                    info@monsconsulting.com
                  </a>
                </Box>
                <Box margin="xsmall" direction="row">
                  <FieldTitle>Morada:</FieldTitle>
                  <Text size="medium">
                    {` Escadinhas da Alba Longa 7/9, RC, 2710-522 Sintra`}
                  </Text>
                </Box>
              </Box>
              <ContactForm />
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </Section>
  )
}

export default Contact
